import React from "react";
import Hero from "../sections/examples/Hero";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import VideoDemo from "../sections/common/VideoDemoNew";

import Commit from "../sections/common/Cta";
import WaveReverse from "../sections/common/WaveReverse";

import SEO from '../components/SEO';
import imgC from "../assets/image/metaimg/showcase.jpg";

const Demo = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Showcasing the very best of Digma's Interactive Videos"
          description="Interactive Art galleries, Hotel Tours, Assembly Videos, Real Estate Brochures and Video Resumes "
          image={imgC}
        />    
        <Helmet>        
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=b2b'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=b2b'}></script>
        </Helmet>   
        <Hero />
        <WaveReverse color="#F7F9FC" />
        <VideoDemo className="grey" title="Neem" name="Sustainable Fashion" projectId="06757B88-748F-4795-8F6E-CB86D5332DC8" />        
        <div className="upsidedown">
        <WaveReverse color="#F7F9FC" />
        </div>
        <VideoDemo title="Drone Hotel Tour" name="Leisure & tourism" projectId="14636da6-686e-465c-8165-a48b9e692a3f" />      
        <WaveReverse color="#F7F9FC" />
        <VideoDemo className="grey" title="Art Gallery" name="Arts & Entertainment" projectId="e3e2de77-696b-4dc9-b0c4-633feb0a6dbe" />
        <div className="upsidedown">
        <WaveReverse color="#F7F9FC" />
        </div>
        <VideoDemo title="Golf Course Flyover" name="Golf" projectId="73E77DA2-FD03-41A6-B2D0-FDFE39581BF2" />        
        <WaveReverse color="#F7F9FC" />
        <VideoDemo className="grey" title="AidCall" name="Informative video" projectId="9DC7A8C9-3FD5-4133-A869-6B291773D820" />        
        <div className="upsidedown">
        <WaveReverse color="#F7F9FC" />
        </div>
        <VideoDemo title="Imove" name="Real Estate" projectId="7ac6da0d-bcf4-4426-b72c-ce14d84071fd" />        
        <WaveReverse color="#F7F9FC" />
        <VideoDemo className="grey pb-20" title="20 Summer Dresses" name="Influencer" projectId="CBC126B9-92C5-4C01-9884-3EC4D6C2EF4B" />        
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
        <Commit />
        </div>

      </PageWrapper>
    </>
  );
};
export default Demo;
